import React from 'react';
import { TabGroup } from "@statikly/funk";
import About from "./About";
import Nau from "./Nau";
import Header from "./Header";
import Ren from "./Ren";

const Nav = () => {
    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center">
            <Header />
            <TabGroup numTabs={4} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList>
                    <TabGroup.Tab
                        index={0}
                        className="h-12 px-12 transition-colors duration-150"
                        activeClassName="bg-black text-white"
                        inactiveClassName="text-black"
                    >
                        Объекты ИС
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={1}
                        className="h-12 px-12 transition-colors duration-150"
                        activeClassName="bg-black text-white"
                        inactiveClassName="text-black"
                    >
                        Директор
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-12 px-12 transition-colors duration-150"
                        activeClassName="bg-black text-white"
                        inactiveClassName="text-black"
                    >
                        Реквизиты
                    </TabGroup.Tab>
                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="p-16 transition-all transform h-64 max-w-5xl"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <About />
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="p-16 transition-all transform h-64 max-w-5xl"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <Nau />
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="p-16 transition-all transform h-64 max-w-5xl"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <Ren />
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    );
};

export default Nav;
import React from 'react';

const Ren = () => {
    return (
        <div>
            <p><b>Полное наименование субъекта малого предпринимательства</b> Общество с ограниченной ответственностью «ФЕРДЕН»</p>
            <p><b>Сокращенное наименование субъекта малого предпринимательства</b> ООО «ФЕРДЕН»</p>
            <p><b>Основной вид деятельности</b> 72.19 – Научные исследования и разработки в области естественных и технических наук</p>
            <p><b>Юридический и почтовый адрес</b> 430031, Республика Мордовия, г. Саранск, ул. Рузаевская, д. 36А, офис 8</p>
            <p><b>Номер и дата государственной регистрации</b> 1227700708630 от 01 ноября 2022</p>
            <p><b>ИНН</b> 9718207417</p>
            <p><b>КПП</b> 130001001</p>
            <p><b>Расчетный счет, полное наименование банка</b> 40702810810001610368, АО "ТИНЬКОФФ БАНК"</p>
            <p><b>Корреспондентский счет</b> 30101810145250000974</p>
            <p><b>БИК банка</b> 044525974</p>
            <p><b>Коды статистики</b></p>
            <p><b>ОКАТО</b> 89401366000</p>
            <p><b>ОКОГУ</b> 4210014</p>
            <p><b>ОКОПФ</b> 12300</p>
            <p><b>ОКФС</b> 16</p>
        </div>
    );
};

export default Ren;
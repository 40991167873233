import React from 'react';
import tyrin from "../img/tyrin.jpg";
const width = {
    width: "25%",
    padding: "1px"
}

const Nau = () => {
    return (
        <div>
            <div className="flex flex-col text-center w-full mb-12">
                <img className="lg:w-2/3 mx-auto leading-relaxed text-base" style={width} src={tyrin} alt="Tyrin" />
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Тюрин Иван Александрович</p>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Генеральный директор ООО «ФЕРДЕН»</p>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">+7-961-643-18-52</p>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">ivantyurin1987@gmail.com</p>

                <h2><b>Кандидат технических наук</b></h2>
            </div>
            <h2>Образование:</h2>
            <p><b>2009-2013</b> ФГБОУ ВПО «Саратовский государственный технический университет имени Гагарина Ю.А.».
                Магистратура и аспирантура на кафедре «Химическая технология»; </p>
            <p><b>2004-2008</b> ФГБОУ ВПО «Саратовский государственный технический университет имени Гагарина Ю.А.».
                Бакалавр техники и технологии по направлению «Химическая технология и биотехнология»</p>
            <p><b>2023</b> «Проектирование и разработка систем искусственного интеллекта: инженер-программист»</p>
            <p><b>2022</b> «Внутрилабораторный контроль»</p>
            <p><b>2020</b> «Управление персоналом»</p>
            <p><b>2019</b> «Управление государственными и муниципальными закупками»</p>
            <p><b>2021-наст. время.</b> ФБУН «Институт биохимической физики им. Н.М. Эмануэля РАН» (ИБХФ РАН), г. Москва. Старший научный сотрудник</p>
            <p><b>2021-2023</b> ООО «Вотерпроф». Генеральный директор</p>
            <p><b>2017-2021</b> ООО «МТЕХ». Генеральный директор</p>
            <p><b>2014-2016</b> АО ЭОКБ "СИГНАЛ" им. А.И. Глухарева, начальник технологического бюро подготовки по спецпроцессам ОГТ.</p>

        </div>
    );
};

export default Nau;
import React from 'react';

const Header = () => {
    return (
        <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">ООО «Ферден»</h1>

            <h3>ОГРН 1227700708630 от 01.11.2022</h3>

            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Разработка инновационных технологий</p>
            <h3>г. Саранск</h3>
            <h2><b>Направления деятельности компании:</b></h2>

            <ul className="text-base">
                <li>Научные исследования и разработки в области естественных и технических наук</li>

                <li>Разработка анодного материала для литий-ионных аккумуляторов</li>

                <li>Разработка, тестирование и модификация программного обеспечения</li>

                <li>Иные виды деятельности</li>
            </ul>
        </div>
    );
};

export default Header;
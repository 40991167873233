import React from 'react';
import rec from "../img/rec.jpg";
import mat from "../img/mat.jpg";

const width = {
    width: "50%",
    padding: "1px"
}

const About = () => {
    return (
        <div>
            <div style={{
                display: "flex"
            }}>
                <img style={width} src={rec} alt="Rectusfacio" />
                <img style={width} src={mat} alt="Material surface calculation" />
            </div>
        </div>
    );
};

export default About;